import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { apiRequest } from "../../utils";

interface UserConfig {
  key: string;
  value: string;
}

const useUserConfigState = () => useState<Map<string, string>>();

const UserConfigContext = createContext<ReturnType<
  typeof useUserConfigState
> | null>(null);

export function UserConfigStateProvider(props: {
  children: ReactNode;
  loaded: boolean;
}) {
  const [userConfig, setUserConfig] = useUserConfigState();
  useEffect(() => {
    if (!props.loaded) {
      return;
    }
    apiRequest<UserConfig[]>("/user/config").then((res) => {
      if (res !== null) {
        const map = new Map<string, string>();
        res.forEach((conf) => map.set(conf.key, conf.value));
        setUserConfig(map);
      }
    });
  }, [setUserConfig, props.loaded]);
  return (
    <UserConfigContext.Provider value={[userConfig, setUserConfig]}>
      {props.children}
    </UserConfigContext.Provider>
  );
}

export const useUserConfigSharedState = () => {
  const value = useContext(UserConfigContext);
  if (value === null) {
    throw new Error("No provider for UserConfig State");
  }
  return value;
};
