import React from "react";
import { ReactNode } from "react";
import { Link as RLink } from "react-router-dom";
import clsx from "clsx";

interface LinkProps {
  link: string;
  onClick?: (e: React.MouseEvent) => void;
  children: ReactNode;
  className?: string;
  title?: string;
  active?: boolean;
  target?: string;
}

export default function Link(props: LinkProps) {
  const click = (e: React.MouseEvent) => {
    if (props.active !== false) {
      props.onClick?.call(e.currentTarget, e);
    }
  };
  const to = props.active !== false ? props.link : "";
  const css = clsx(
    props.className,
    props.active !== false ? "cursor-pointer hover:underline" : "cursor-default"
  );
  if (props.active !== false) {
    return (
      <RLink
        className={css}
        onClick={click}
        to={to}
        title={props.title}
        target={props.target}
      >
        {props.children}
      </RLink>
    );
  } else {
    return (
      <div
        className={css}
        title={props.title}
      >
        {props.children}
      </div>
    );
  }
}
